import React from 'react';
import HeaderImage from '../../assets/img/opa-congressional-outreach-header.png';
import FirstCard from '../../assets/img/opa-card1.png';
import SecondCard from '../../assets/img/opa-card2.png';
import ThirdCard from '../../assets/img/opa-card3.png';
import FourthCard from '../../assets/img/opa-card4.png';
import FifthCard from '../../assets/img/opa-card5.png';
import SixthCard from '../../assets/img/opa-card6.png';

function ImageCard({ imageUrl, linkHref, imageTitle }) {
  return (
    <a href={linkHref} className="grid-item" style={{ textAlign: 'center', position: 'relative' }}>
      <img
        src={imageUrl || FirstCard}
        alt={imageTitle}
        style={{ width: '100%', height: 'auto', transition: 'transform 0.3s, box-shadow 0.3s' }}
      />
      {!imageUrl && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            textAlign: 'center',
            padding: '10px',
            width: '90%',
            boxSizing: 'border-box',
          }}
        >
          <p style={{ fontSize: 'clamp(1em, 3vw, 1.5em)', margin: 0 }}>{imageTitle}</p>
        </div>
      )}
    </a>
  );
}

function CongressionalOutreach() {
  const cards = [
    { imageUrl: FirstCard, linkHref: '/link1', imageTitle: 'Link 1' },
    { imageUrl: SecondCard, linkHref: '/link2', imageTitle: 'Link 2' },
    { imageUrl: ThirdCard, linkHref: '/link3', imageTitle: 'Link 3' },
    { imageUrl: FourthCard, linkHref: '/link4', imageTitle: 'Link 4' },
    { imageUrl: FifthCard, linkHref: '/link5', imageTitle: 'Link 5' },
    { imageUrl: SixthCard, linkHref: '/link6', imageTitle: 'Link 6' },
  ];

  return (
    <div
      className="congressional-outreach"
      style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, boxSizing: 'border-box' }}
    >
      {/* Header Section */}
      <header
        className="header"
        style={{
          width: '100%',
          backgroundColor: '#f4f4f4',
          textAlign: 'center',
          padding: '20px 0',
          position: 'relative',
        }}
      >
        <img
          src={HeaderImage}
          alt="Congressional Outreach Header"
          style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            textAlign: 'center',
            padding: '10px',
            width: '90%',
            boxSizing: 'border-box',
          }}
        >
          <h1 style={{ fontSize: 'clamp(1.5em, 5vw, 2.5em)', margin: 0 }}>Congressional Outreach</h1>
          <p style={{ fontSize: 'clamp(1em, 3vw, 1.2em)', margin: '10px 0 0' }}>Connecting Leaders and Communities</p>
        </div>
      </header>

      <main
        className="image-grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '20px',
          padding: '20px',
          maxWidth: '850px',
          margin: '0 auto',
        }}
      >
        {cards.map((card, index) => (
          <ImageCard key={index} imageUrl={card.imageUrl} linkHref={card.linkHref} imageTitle={card.imageTitle} />
        ))}
      </main>

      {/* Footer Section */}
      <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <p>Phone: (555) 123-4567</p>
        <p>Email: outreach@example.com</p>
      </div>
    </div>
  );
}

export default CongressionalOutreach;
