import React, { ChangeEvent, useEffect } from 'react';
import { sortBy } from 'lodash';
import withContext from '../../ContextAPI/Context_HOC';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import ServiceOpsRichTextResolver from '../Utilities/ServiceOpsRichTextResolver';
import ServiceOpsFlatter from '../Utilities/ServiceOpsFlatter';
import type ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import type { ServiceOpportunitiesFlat } from './Model/service_opportunities_flat';
import type { ServiceOpportunities } from '../../Kentico/DigitalDesign/service_opportunities';
// import DisplayResourcesList from '../DisplayResourcesList';

function UnitedStates(props: { context: { digitalDesignContent: ContentProviderEndpoint } }) {
  const [value, setValue] = React.useState<Number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [serviceOpsList, setServiceOpsList] = React.useState<ServiceOpportunitiesFlat[]>([]);
  const contentProvider = props.context.digitalDesignContent;

  useEffect(() => {
    const subscription = contentProvider
      .items<ServiceOpportunities>()
      .type('service_opportunities')
      .containsFilter('elements.service_opportunities_taxonomy', ['united_states'])
      .elementsParameter([
        'base__title',
        'base__body',
        'base__datepublished',
        'department',
        'location',
        'document_download',
        'position_type',
      ])
      .toObservable()
      .subscribe((response) => {
        if (!response.isEmpty) {
          const itemsResolved = ServiceOpsRichTextResolver(response.items);
          const itemsResolvedFlattened = ServiceOpsFlatter(itemsResolved);
          const itemsResolvedFlattenedSorted = sortBy(
            itemsResolvedFlattened,
            'isSummerPosition',
            'department.value',
            'base__title.value'
          );
          setServiceOpsList(itemsResolvedFlattenedSorted);
        }
      });
    return () => subscription.unsubscribe();
  }, [contentProvider]);

  const handleChange = (event: ChangeEvent<{}>, newValue: Number) => {
    setValue(newValue);
  };

  return (
    <div>
      <h1>United States Service Opportunities</h1>
      <Paper>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="United States" component={Link} data-cy="us-opportunities" to="/resources/service-opportunities" />
          <Tab label="International" component={Link} data-cy="international-opportunities" to="/resources/international" />
          <Tab label="World Center" component={Link} data-cy="world-center-opportunities" to="/resources/world-center" />
        </Tabs>
      </Paper>
      <br />
      <p>
        <em>
          Let them step forth to take their places in the arena of service where their talents and skills, their specialized
          training, their material resources, their offers of time and energy and, above all, their dedication to Bahá’í
          principles, can be put to work in improving the lot of man.
        </em>
      </p>

      <p>Universal House of Justice</p>

      <p>
        The National Hazíratu’l-Quds is the seat of the National Spiritual Assembly. Through the Baha’i National Organization,
        devoted friends serve the institution which helps to unify, to coordinate, and to consolidate the activities and
        directions of the National Spiritual Assembly. It is a special bounty and honor to be able to devote oneself fully and
        directly to the advancement of the Cause through service. If you wish to offer a period of service to support the
        spiritual and administrative work of the National Assembly, you are welcome to express your interest by emailing{' '}
        <a href="mailto:BNO-Service@usbnc.org">BNO-Service@usbnc.org</a>.
      </p>

      <p>
        Bahá’ís everywhere are engaged in rebuilding the world to a new design – neighborhood by neighborhood, with a new kind of
        community life that welcomes, includes, honors, and respects all. We want our national organization to reflect in every
        way our unshakeable commitment to the pursuit of this vision. This means ensuring that all those who have the bounty of
        serving the National Spiritual Assembly in a host of ways are fully representative of the diversity of the people of this
        nation, and that we create spaces for the unique contributions of people from every race and nationality. This also means
        that we are committed to an organizational life that seeks to ensure that women and men in equal measure are accorded a
        role in its stewardship.
      </p>
      {/* <hr />
      {DisplayResourcesList({ dataArray: serviceOpsList, type: 'Position' })} */}
    </div>
  );
}

export default withContext(UnitedStates);
